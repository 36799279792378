import React from 'react';
import { useState } from 'react';
import { Container, Wrapper, Title, Desc, CardContainer } from './ProjectsStyle';
import ProjectCard from '../Cards/ProjectCards';
import { projects } from '../../data/constants';

const Projects = ({ openModal, setOpenModal }) => {
  const [toggle, setToggle] = useState('all');

  return (
    <Container id="projects">
      <Wrapper>
        <Title>Projects</Title>
        <Desc>
          We have worked on a wide range of projects. Here are some of our previous works.
        </Desc>
        {/* Remove the ToggleButtonGroup and its child ToggleButton components */}
        <CardContainer>
          {toggle === 'all' && projects.map((project) => (
            <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
          ))}
          {projects
            .filter((item) => item.category === toggle)
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
        </CardContainer>
      </Wrapper>
    </Container>
  );
};

export default Projects;
